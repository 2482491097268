<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2>Типы материалов</h2>
      <div>
        <UIButton
          class="add-btn"
          text="Добавить"
          leftIcon="bi-plus"
          @click="addTypeData"
        />
      </div>
    </div>
    <TableSlot v-bind="typeMaterial" @rowClick="showTypeMaterialDetails" />
  </div>
</template>

<script>
import { API_type_material_list } from "@/services/references/typeMaterial";
import TableSlot from "@/components/TableSlot";
import UIButton from "@/components/UI/Button";

export default {
  name: "typeMaterialList",
  components: { TableSlot, UIButton },
  data() {
    return {
      typeMaterial: {
        columns: [
          {
            name: "Код",
            path: "WorkDocument.Document.IdentifiedObject.enproCode",
          },
          {
            name: "Наименование",
            path: "WorkDocument.Document.IdentifiedObject.name",
          },
          {
            name: "Тип затрат",
            path: "costType",
          },
          {
            name: "ЕИ",
            path: "quantity",
          },
          {
            name: "Цена единицы",
            path: "estUnitCost.value",
          },
          {
            name: "Статус",
            path: "WorkDocument.Document.status.value",
          },
        ],
        data: [],
        filters: {
          "WorkDocument.Document.IdentifiedObject.name": "",
          "WorkDocument.Document.IdentifiedObject.enproCode": "",
        },
      },
    };
  },
  mounted() {
    API_type_material_list().then((res) => {
      this.typeMaterial.data = res;
    });
  },
  methods: {
    showTypeMaterialDetails(item) {
      this.$router.push({
        path: `/references/type-material/${item.id}`,
      });
    },
    addTypeData() {
      this.$router.push({
        path: `/references/type-material/add`,
      });
    },
  },
};
</script>

<style scoped></style>
